<template>
  <div>
    <h3>1回のポストで掲載する画像枚数</h3>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <v-select
            v-model="item.photo_count"
            name="photo_nums"
            :items="photoNumItems"
            no-data-text="画像枚数"
            label="画像枚数"
            success
            outlined
            hint="画像枚数の指定[必須項目]"
            persistent-hint
            :rules="require_rule"
            class="mt-2"
          ></v-select>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  set,
  reactive,
  toRefs,
  defineComponent,
  onMounted,
} from "@vue/composition-api";

export default defineComponent({
  props: ["template_config"],
  setup(props, ctx) {
    const state = reactive({
      item: {
        photo_count: 1,
      },
    });

    const photoNumItems = [
      { value: 1, text: "1枚" },
      { value: 2, text: "2枚" },
      { value: 3, text: "3枚" },
      { value: 4, text: "4枚" },
    ];

    const getData = async () => {
      const config = props.template_config;
      for (const [key, value] of Object.entries(config)) {
        if (key == "photo_count") {
          set(state.item, "photo_count", value);
        }
      }
    };

    onMounted(async () => {
      // データセット
      if (props.template_config) {
        await getData(props.template_config);
      }
    });

    const saveConfig = async () => {
      return { "photo_count": state.item.photo_count };
    };

    return {
      ...toRefs(state),
      photoNumItems,
      require_rule: [(v) => !!v || "必須選択項目です"],
      saveConfig,
    };
  },
});
</script>
