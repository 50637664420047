import setting from "@/common/setting.js";

// 共通で使用する日付関連の定義
const utilDate = {
  // getToday
  // 引数：n=数値(指定された数値を加算する)
  // 戻り値：日付型
  getToday(n = 0, shop_dateline = null) {
    const dateline = shop_dateline ?? setting.default_dateline;
    let today = new Date();
    if (Number(dateline) > today.getHours()) {
      today.setDate(today.getDate() - 1);
    }
    if (n != 0) {
      today.setDate(today.getDate() + n);
    }
    return today;
  },

  // getDateFormatFull
  // 引数：d=日付型
  // 戻り値："YYYY-MM-DD [曜日]"の文字列を返却
  getDateFormatFull(d) {
    if (!d) return null;
    return (
      d.getFullYear() +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d.getDate()).slice(-2) +
      " [" +
      setting.weekday[d.getDay()] +
      "]"
    );
  },

  // getDateFormatFullDisplay
  // 引数：d=日付型
  // 戻り値："YYYY/MM/DD [曜日]"の文字列を返却
  getDateFormatFullDisplay(d) {
    if (!d) return null;
    return (
      d.getFullYear() +
      "/" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + d.getDate()).slice(-2) +
      " [" +
      setting.weekday[d.getDay()] +
      "]"
    );
  },

  // getNumberMonthFormatFull
  // 引数：numMonth=YYYYMMの数値
  // 戻り値："YYYY年MM月"の文字列を返却
  getNumberMonthFormatFull(numMonth) {
    if (!numMonth) return null;
    const strMonth = numMonth.toString();
    return strMonth.slice(0, 4) + "年" + strMonth.slice(4, 6) + "月";
  },

  // getMonthFormatLong
  // 引数：d=日付型
  // 戻り値："YYYY-MM"の文字列を返却
  getMonthFormatLong(d) {
    if (!d) return null;
    return d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2);
  },

  // getDateFormatLong
  // 引数：d=日付型
  // 戻り値："YYYY-MM-DD"の文字列を返却
  getDateFormatLong(d) {
    if (!d) return null;
    return (
      d.getFullYear() +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d.getDate()).slice(-2)
    );
  },

  // getDateFormatLongDisplay
  // 引数：d=日付型
  // 戻り値："YYYY/MM/DD"の文字列を返却
  getDateFormatLongDisplay(d) {
    if (!d) return null;
    return (
      d.getFullYear() +
      "/" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + d.getDate()).slice(-2)
    );
  },

  // getDateFormatShort
  // 引数：d=日付型
  // 戻り値："MM-DD[曜日]"の文字列を返却
  getDateFormatShort(d) {
    if (!d) return null;
    return (
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d.getDate()).slice(-2) +
      "[" +
      setting.weekday[d.getDay()] +
      "]"
    );
  },

  // getDateFormatShortDisplay
  // 引数：d=日付型
  // 戻り値："MM/DD[曜日]"の文字列を返却
  getDateFormatShortDisplay(d) {
    if (!d) return null;
    return (
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + d.getDate()).slice(-2) +
      "[" +
      setting.weekday[d.getDay()] +
      "]"
    );
  },

  // getDateFormatNum
  // 引数：d=日付型
  // 戻り値："YYYYMMDD"の文字列を返却
  getDateFormatNum(d) {
    if (!d) return null;
    return (
      d.getFullYear() +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      ("0" + d.getDate()).slice(-2)
    );
  },

  // getDatetimeFormatLong
  // 引数：d=日付型
  // 戻り値："YYYY-MM-DD H:M"の文字列を返却
  getDatetimeFormatLong(d) {
    if (!d) return null;
    return (
      d.getFullYear() +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d.getDate()).slice(-2) +
      " " +
      ("0" + d.getHours()).slice(-2) +
      ":" +
      ("0" + d.getMinutes()).slice(-2)
    );
  },

  // getDatetimeFormatLongDisplay
  // 引数：d=日付型
  // 戻り値："YYYY/MM/DD H:M"の文字列を返却
  getDatetimeFormatLongDisplay(d) {
    if (!d) return null;
    return (
      d.getFullYear() +
      "/" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + d.getDate()).slice(-2) +
      " " +
      ("0" + d.getHours()).slice(-2) +
      ":" +
      ("0" + d.getMinutes()).slice(-2)
    );
  },

  // getDatetimeFormatNum
  // 引数：d=日付型
  // 戻り値："YYYYMMDD"の文字列を返却
  getDatetimeFormatNum(d) {
    if (!d) return null;
    return (
      d.getFullYear() +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      ("0" + d.getDate()).slice(-2) +
      ("0" + d.getHours()).slice(-2) +
      ("0" + d.getMinutes()).slice(-2) +
      ("0" + d.getSeconds()).slice(-2)
    );
  },

  // getTimeFormat
  // 引数：d=日付型
  // 戻り値："H:M"の文字列を返却
  getTimeFormat(d) {
    if (!d) return null;
    return (
      ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2)
    );
  },

  // getDateWeekday
  // 引数：d=日付型
  // 戻り値：getDay()の結果を返却
  getDateWeekday(d) {
    if (!d) return null;
    return d.getDay();
  },

  // convertStrToDate
  // 引数：str=文字列("YYYY-MM-DD")の形
  // 戻り値：date型を返却
  convertStrToDate(str) {
    if (!str) return null;
    const result = new Date(str.replace(/-/g, "/"));
    return result;
  },

  // convertSplitStrToDate
  // 引数：str=文字列("YYYY-MM-DDTHH:MI:SS")の形
  // 戻り値：date型を返却
  convertSplitStrToDate(str) {
    if (!str) return null;
    const dt = str.split("T");
    const result = new Date(dt[0].replace(/-/g, "/") + " " + dt[1]);
    return result;
  },

  // getScheduleTimeSelectStart
  // 引数：なし
  // 戻り値：スケジュール選択時のセレクトボックスの内容を取得
  getScheduleTimeSelectStart(shop_dateline = null) {
    const base_hour = shop_dateline ?? setting.default_dateline;
    let array = [];
    for (let i = 0; i < 24; i++) {
      let hour = Number(base_hour) + i;
      if (hour >= 24) {
        hour = hour - 24;
      }
      array.push(("0" + hour).slice(-2) + ":" + "00");
      array.push(("0" + hour).slice(-2) + ":" + "30");
    }
    return array;
  },

  // getScheduleTimeSelectEnd
  // 引数：なし
  // 戻り値：スケジュール選択時のセレクトボックスの内容を取得
  getScheduleTimeSelectEnd(shop_dateline = null) {
    const base_hour = shop_dateline ?? setting.default_dateline;
    let array = [];
    let hour = Number(base_hour);
    for (let i = 1; i <= 24; i++) {
      array.push(("0" + hour).slice(-2) + ":" + "30");
      hour = Number(base_hour) + i;
      if (hour >= 24) {
        hour = hour - 24;
      }
      array.push(("0" + hour).slice(-2) + ":" + "00");
    }
    return array;
  },

  // convert24TimeStart
  // 日付変更時刻より小さい場合、24を加算した時刻を返す
  // 引数：time : String
  // 戻り値：String
  convert24TimeStart(time, shop_dateline = null) {
    const dateline = shop_dateline ?? setting.default_dateline;
    let hour = Number(time.split(":")[0]);
    if (hour < dateline) {
      hour = hour + 24;
    }
    return ("0" + String(hour)).slice(-2) + ":" + time.split(":")[1];
  },
  // convert24TimeEnd
  // 日付変更時刻より小さい場合、24を加算した時刻を返す
  // 引数：time : String
  // 戻り値：String
  convert24TimeEnd(time, shop_dateline = null) {
    const dateline = Number(shop_dateline ?? setting.default_dateline);
    let hour = Number(time.split(":")[0]);
    if (hour <= dateline) {
      hour = hour + 24;
    }
    return ("0" + String(hour)).slice(-2) + ":" + time.split(":")[1];
  },

  // checkScheduleTime
  // 開始時刻と終了時刻を比べて整合性をチェック
  // 引数：start_time, end_time
  // 戻り値：boolean
  checkScheduleTime(start_time, end_time, shop_dateline = null) {
    const dateline = shop_dateline ?? setting.default_dateline;
    if (
      utilDate.convert24TimeStart(start_time, dateline) <=
      utilDate.convert24TimeEnd(end_time, dateline)
    ) {
      return true;
    }
    return false;
  },
};

export default utilDate;
