<template>
  <div>
    <app-bar
      ref="refAppBar"
      @on_back="onBack"
      @save="saveButton"
      @setting="settingButton"
      @update="updateButton"
    ></app-bar>
    <div class="pl-4 pr-4 mb-2 d-flex flex-wrap">
      <div class="flex_center">
        <span class="font-weight-bold">{{ siteContent.name }}</span>
        &nbsp;
        <span
          :style="shopSite.color.length != 0 ? 'color:' + shopSite.color : ''"
          >{{ shopSite.sites_name }}</span
        >
      </div>
      <div class="flex_center ml-auto mini">
        最低更新間隔{{ siteContent.min_interval }}分
      </div>
    </div>
    <div class="">
      <div
        v-if="siteContent.manual != null && siteContent.manual.length != 0"
        class="text-subtitle-2 pa-1 blue--text blue lighten-5"
      >
        {{ siteContent.manual }}
      </div>
      <div
        v-if="
          siteContent.content_format_ids.length > 0 &&
          siteContent.content_format_ids[0] != 1
        "
        class="text-subtitle-2 pa-1 red--text red lighten-5"
      >
        本コンテンツは、専用のフォーマットを使用してテンプレートを作成してください。共通フォーマットは使用できません。
      </div>
    </div>
    <div style="position: relative">
      <v-tabs v-model="refTabs" class="mb-3">
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          v-for="tab in tabItems"
          :key="tab.mode"
          class="font-weight-bold"
          :disabled="tab.disabled"
          >{{ tab.name }}</v-tab
        >
      </v-tabs>
      <v-tabs-items v-model="refTabs" touchless>
        <v-tab-item
          v-for="tab in tabItems"
          :key="tab.mode"
          :disabled="tab.disabled"
        >
          <component
            v-bind:is="tab.component"
            ref="refTabItemComponent"
            :child_content_header="tab.childContentHeader"
            :site_content="tab.siteContent"
            :use_template="tab.use_template"
          ></component>
        </v-tab-item>
      </v-tabs-items>
      <v-overlay
        :value="overlay"
        absolute
        opacity="0.1"
        z-index="4"
      ></v-overlay>
      <v-snackbar v-model="overlay" timeout="-1" color="orange"
        >現在、自動更新中です。編集する場合は停止して下さい。
      </v-snackbar>
    </div>
    <!-- 自動更新ダイアログ -->
    <v-dialog
      v-model="updateDialog"
      scrollable
      persistent
      width="100%"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">自動更新を開始</span>
        </v-card-title>
        <v-card-text>
          <h3>初回の更新時刻を指定</h3>
          <v-radio-group v-model="updateMode">
            <v-radio
              v-for="rItem in radioItems"
              :key="rItem.index"
              :value="rItem.index"
            >
              <template v-slot:label
                >{{ rItem.label }}・・・{{ rItem.sub_label }}</template
              >
            </v-radio>
          </v-radio-group>
          <div>
            <v-container fluid>
              <v-row>
                <v-col cols="0" sm="1"></v-col>
                <v-col cols="6" sm="3">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :disabled="updateMode != 2"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="updateDate"
                        label="日付選択"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :disabled="updateMode != 2"
                        hide-details="false"
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="updateDate"
                      no-title
                      @input="menu = false"
                      :first-day-of-week="0"
                      locale="ja-jp"
                      :disabled="updateMode != 2"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" sm="3">
                  <v-form ref="refForm">
                    <div class="d-flex flex-wrap">
                      <div class="flex_center">
                        <div class="select_item">
                          <v-select
                            class="select_hour"
                            v-model="updateHour"
                            :items="selectITem.hourItem"
                            item-text="text"
                            item-value="value"
                            label="時"
                            dense
                            hide-details="false"
                            :disabled="updateMode != 2"
                            :rules="[required]"
                          >
                          </v-select>
                        </div>
                      </div>
                      <div class="flex_center">
                        <div class="select_item ml-1">
                          <v-select
                            class="select_minute"
                            v-model="updateMinute"
                            :items="selectITem.minuteItem"
                            item-text="text"
                            item-value="value"
                            label="分"
                            dense
                            hide-details="false"
                            :disabled="updateMode != 2"
                            :rules="[required]"
                          ></v-select>
                        </div>
                      </div>
                    </div>
                  </v-form>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelUpdateDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
              <v-col cols="6" sm="3">
                <general-button
                  btn_type="info"
                  btn_block
                  @click-event="onSubmitUpdateDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-timer-outline</v-icon></template
                  >開始</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 設定ダイアログ -->
    <v-dialog
      v-model="settingDialog"
      scrollable
      persistent
      width="100%"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">設定</span>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <h3 class="mt-0">ランダム更新</h3>
                <p class="pl-0">
                  ONにすると毎回決まった更新時刻ではなく、ランダムに更新更新を設定します（次回更新時刻+1分〜9分の間で変動）
                  <span class="pl-0 text-red" v-show="!is_random_enable">
                    <br />※この媒体はランダム更新の設定を変更できません。
                  </span>
                </p>
                <v-switch
                  v-model="is_random"
                  :disabled="!is_random_enable"
                  :label="`${is_random ? 'ON' : 'OFF'}`"
                ></v-switch>
              </v-col>
              <v-col cols="12">
                <h3>更新時間帯</h3>
                <p class="pl-0">更新する時間帯をONにして下さい。</p>
                <div
                  v-for="num in time_zone_default_list"
                  :key="num"
                  class="time_zone_box"
                >
                  <v-btn
                    :color="
                      time_zone_list.find((v) => v == num)
                        ? `primary`
                        : `grey lighten-3`
                    "
                    small
                    elevation="0"
                    class="pa-0 mr-1 mb-1"
                    @click="onClickTimeZone(num)"
                    >{{ num }}時</v-btn
                  >
                </div>
                <p>※店舗管理の更新時間帯で設定した内容が優先されます。</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelSettingDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
              <v-col cols="6" sm="3">
                <general-button
                  btn_type="info"
                  btn_block
                  @click-event="onSubmitSettingDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-content-save</v-icon></template
                  >保存</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  onMounted,
  defineComponent,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate.js";
import selectITem from "@/common/selectItem.js";
import tabSpecifyComponent from "./component/tabSpecify.vue";
import tabTimerComponent from "./component/tabTimer.vue";
import appBar from "./appBar.vue";
import modelTemplate from "@/model/templates";
import { useRouter } from "@/utils";

export default defineComponent({
  components: {
    appBar,
    tabTimerComponent,
    tabSpecifyComponent,
  },
  props: ["update_content_id", "shop_site_id", "site_content_id", "site_id"],
  setup(props, ctx) {
    const { router } = useRouter();
    const siteContentsRepository = repositoryFactory.get("siteContents");
    const updateContentsRepository = repositoryFactory.get("updateContents");
    const refAppBar = ref();
    const refTabItemComponent = ref();
    const refTabs = ref();
    const refForm = ref();
    const state = reactive({
      overlay: false,
      // 店舗媒体
      shopSite: {
        id: 0,
        shop_id: 0,
        site_id: 0,
        config: "",
        sites_id: 0,
        sites_name: "",
        sites_short_name: "",
        memo: "",
        color: "",
        label: "",
      },
      // 媒体コンテンツ
      siteContent: {
        name: "",
        content_format_ids: [],
        min_interval: 0,
        manual: "",
      },
      // 更新コンテンツ全体
      uContent: {
        id: 0,
        shop_site_id: 0,
        site_content_id: 0,
        is_auto: false,
        current_mode: 0,
        config: "",
        start_update: null,
        last_update: null,
        next_update: null,
        time_zone: setting.time_zone_default,
        is_random: false,
        site_id: 0,
        child_update_headers: [],
      },
      tabItems: [],
      // 自動更新開始ダイアログ
      updateDialog: false,
      updateMode: 1,
      updateDate: null,
      updateHour: 0,
      updateMinute: 0,
      // 設定ダイアログ
      settingDialog: false,
      time_zone_list: [],
      time_zone_default_list: setting.time_zone_default.split(","),
      // ランダム更新
      is_random: false,
      is_random_enable: true,
    });
    const tabs = [
      {
        mode: setting.mode_specify,
        name: "時刻指定更新",
        component: tabSpecifyComponent,
        siteContent: {},
        childContentHeader: {},
        disabled: false,
        use_template: true,
      },
      {
        mode: setting.mode_timer,
        name: "タイマー更新",
        component: tabTimerComponent,
        siteContent: {},
        childContentHeader: {},
        disabled: false,
        use_template: true,
      },
    ];
    const radioItems = [
      {
        index: 1,
        label: "いますぐ",
        sub_label: "初回の更新時刻が5分後に設定されます",
      },
      {
        index: 2,
        label: "時間指定",
        sub_label: "初回の更新時刻を指定できます",
      },
    ];

    const _sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const setOverlay = (overlay) => {
      state.overlay = overlay;
    };

    // const init = async () => {
    onMounted(async () => {
      await store.dispatch("loadingIcon/showIcon");

      // テンプレート読み込み
      if (store.getters["templates/getTemplateList"].length == 0) {
        await modelTemplate.setStore();
      }

      // synchroContentsの新規登録ボタンで選ばれたID
      set(state.uContent, "site_id", parseInt(props.site_id));
      set(state.uContent, "shop_site_id", parseInt(props.shop_site_id));
      set(state.uContent, "site_content_id", parseInt(props.site_content_id));
      if (props.update_content_id == null || props.update_content_id == 0) {
        // 新規作成
        set(state.uContent, "current_mode", setting.mode_specify);
        if (
          setting.next_update_random_site_ids.includes(state.uContent.site_id)
        ) {
          set(state.uContent, "is_random", true);
        }
        // console.log("NEW");
        refAppBar.value.action({ func: "editmode_set", mode: "new" });
      } else {
        // console.log("UPDATE");
        // 更新
        refAppBar.value.action({ func: "editmode_set", mode: "update" });
        await updateContentsRepository
          .get_all(props.update_content_id)
          .then((response) => {
            if (response.data) {
              const rd = response.data;
              set(state.uContent, "id", rd.id);
              set(state.uContent, "shop_site_id", rd.shop_site_id);
              set(state.uContent, "site_content_id", rd.site_content_id);
              set(state.uContent, "is_auto", rd.is_auto);
              set(state.uContent, "current_mode", rd.current_mode);
              set(state.uContent, "config", rd.config);
              set(state.uContent, "start_update", rd.start_update);
              set(state.uContent, "last_update", rd.last_update);
              set(state.uContent, "next_update", rd.next_update);
              set(state.uContent, "time_zone", rd.time_zone);
              set(state.uContent, "is_random", rd.is_random);
              set(state.uContent, "site_id", rd.site_id);
              set(
                state.uContent,
                "next_child_update_content_id",
                rd.next_child_update_content_id
              );
              set(
                state.uContent,
                "child_update_headers",
                rd.child_update_headers
              );
            }
          })
          .catch((error) => {
            throw "ERROR:データ取得エラー (" + error + ")";
          });
        refAppBar.value.action({
          func: "auto_set",
          mode: state.uContent.is_auto,
        });
        setOverlay(state.uContent.is_auto);
        // console.log("state.uContent", state.uContent);
      }

      // shopSite取得
      const tmpShopSite = store.getters["shop_sites/getShopSiteById"](
        state.uContent.shop_site_id
      );
      Object.keys(state.shopSite).forEach(function (key) {
        set(state.shopSite, key, tmpShopSite[key]);
      });
      set(
        state.shopSite,
        "label",
        state.shopSite.sites_short_name +
          (state.shopSite.memo ? "[" + state.shopSite.memo + "]" : "")
      );
      set(
        state.shopSite,
        "sites_name",
        state.shopSite.sites_name +
          (state.shopSite.memo ? "[" + state.shopSite.memo + "]" : "")
      );

      // siteContent取得
      await siteContentsRepository
        .get(state.uContent.site_content_id)
        .then((response) => {
          if (response.data) {
            set(state.siteContent, "name", response.data.name);
            set(
              state.siteContent,
              "content_format_ids",
              response.data.content_format_ids ?? []
            );
            set(state.siteContent, "min_interval", response.data.min_interval);
            set(state.siteContent, "manual", response.data.manual);
          }
        })
        .catch((error) => {
          throw "ERROR:データ取得エラー (" + error + ")";
        });

      // データセット＆タブ表示
      setDataTabs();
      for (let key = 0; key < tabs.length; key++) {
        state.tabItems.push(tabs[key]);
      }

      refTabs.value = state.uContent.current_mode;

      await store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    });

    // init();

    const setDataTabs = async () => {
      for (let mode = 0; mode < tabs.length; mode++) {
        // console.log("setDataTabs_tab_mode", mode);
        // テンプレートを使用しないコンテンツの場合、use_templateはfalseにする
        if (state.siteContent.content_format_ids.length === 0) {
          tabs[mode].use_template = false;
        }
        if (!tabs[mode].disabled) {
          tabs[mode].siteContent = state.siteContent;
          tabs[mode].childContentHeader =
            state.uContent.child_update_headers.filter((v) => v.mode == mode);
        }
      }
    };

    // 更新内容を保存する
    // isCheck:確認メッセージを出す時、true
    // uContentOnly:更新コンテンツテーブルのみ保存したい時、true（設定ダイアログ保存時、trueにする）
    const saveButton = async (isCheck = true, uContentOnly = false) => {
      // 編集中の子コンポーネントを取得し、事前チェックや保存処理を行う
      // 子コンポーネントが作られていない場合があるため、refTabItemComponentをループして編集中のコンポーネントを取得する
      const currentTab = refTabs.value;
      let comIndex = null;
      for (let i = 0; i < ctx.refs["refTabItemComponent"].length; i++) {
        if (currentTab == ctx.refs["refTabItemComponent"][i].getMode()) {
          comIndex = i;
          break;
        }
      }
      if (comIndex == null) {
        alert("システムエラーが発生しました。もう一度操作してください。");
        return false;
      }

      // tab_componentに現在編集中のコンポーネントが入る
      const tab_component = ctx.refs["refTabItemComponent"][comIndex];

      if (!uContentOnly) {
        if (!(await tab_component.checkPreSave())) {
          return false;
        }

        if (isCheck) {
          if (!window.confirm("保存してよろしいですか？")) {
            return false;
          }
        }
      }

      await store.dispatch("loadingIcon/showIcon"); // ローディング表

      // update_contentsのデータがないとき作成する
      set(state.uContent, "current_mode", tab_component.getMode());
      const params = state.uContent;
      if (params.id == 0) {
        await updateContentsRepository
          .create(params)
          .then((response) => {
            if (response.data) {
              // データ追加OK
              set(state.uContent, "id", response.data.id); // idをセット
            }
          })
          .catch((error) => {
            throw (
              "ERROR:dialogComponent.vue/onSubmit templateRepository.create (" +
              error +
              ")"
            );
          });
        refAppBar.value.action({ func: "editmode_set", mode: "update" });
      } else {
        await updateContentsRepository
          .update(params)
          .then((response) => {
            if (response.data) {
              // データ更新OK
              return true;
            }
          })
          .catch((error) => {
            throw (
              "ERROR:dialogComponent.vue/onSubmit templateRepository.create (" +
              error +
              ")"
            );
          });
      }

      if (!uContentOnly) {
        // 子更新ヘッダーおよび子更新コンテンツを保存する
        await tab_component.save(state.uContent);
      }

      await store.dispatch("loadingIcon/hideIcon"); // ローディング非表示

      return true;
    };

    // 更新を開始または止める
    const updateButton = async (btnStatus) => {
      let param_flg = false;
      if (btnStatus == "stopping") {
        // 先に保存処理を行う
        if (!(await saveButton(false))) {
          return false;
        }

        // 自動更新 開始
        if (refTabs.value == setting.mode_specify) {
          // 自動更新が開始できるかチェックする
          let check_result = await updateContentsRepository
            .update_next_check(state.uContent.id)
            .then((response) => {
              if (response.data) {
                return response.data.result;
              }
            })
            .catch((error) => {
              throw (
                "ERROR:index.vue/updateButton updateContentsRepository.update_next_check (" +
                error +
                ")"
              );
            });
          if (!check_result) {
            alert(
              "更新設定が無い、又は、次回の更新時刻が取得できない為、自動更新を開始できません"
            );
            return false;
          }

          // 時刻指定の場合、確認メッセージ
          if (!window.confirm("自動更新を開始してよろしいですか？")) {
            return false;
          }
        } else {
          // タイマーの場合、ダイアログ表示
          const dt = new Date();
          dt.setMinutes(dt.getMinutes() + 10);
          state.updateDate = utilDate.getDateFormatLong(dt);
          state.updateHour = dt.getHours();
          state.updateMinute = dt.getMinutes();
          state.updateDialog = true;

          return false;
        }

        param_flg = true;
      } else {
        if (!window.confirm("自動更新を停止してよろしいですか？")) {
          return false;
        }
        param_flg = false;
      }

      const params = state.uContent;
      set(params, "is_auto", param_flg);
      set(params, "next_update_set", param_flg);
      const next_result = await updateContentsRepository
        .update_next(params)
        .then((response) => {
          if (response.data) {
            return response.data.result;
          }
        })
        .catch((error) => {
          throw (
            "ERROR:index.vue/updateButton updateContentsRepository.update_next (" +
            error +
            ")"
          );
        });
      if (!next_result) {
        alert(
          "更新設定が無い、又は、次回の更新時刻が取得できない為、自動更新を開始できません"
        );
        return false;
      }
      set(state.uContent, "is_auto", param_flg);
      set(state.uContent, "next_update_set", param_flg);
      setOverlay(state.uContent.is_auto);
      refAppBar.value.action({
        func: "auto_set",
        mode: state.uContent.is_auto,
      });
      // 更新を開始した時、画面を閉じる
      if (state.uContent.is_auto) {
        await router.push({
          name: "synchro-contents",
        });
      }
    };

    const cancelUpdateDialog = async () => {
      state.updateDialog = false;
      state.updateMode = 1;
      state.updateDate = null;
      state.updateHour = 0;
      state.updateMinute = 0;
    };

    const onSubmitUpdateDialog = async () => {
      // 入力チェック 過去の日付は指定できない
      if (state.updateMode == 2) {
        if (!refForm.value.validate()) {
          alert("時刻指定に誤りがあります。（必須入力です）");
          return false;
        }
        const checkDatetime =
          state.updateDate +
          " " +
          ("00" + state.updateHour).slice(-2) +
          ":" +
          ("00" + state.updateMinute).slice(-2);
        const limitDatetime = new Date();
        limitDatetime.setMinutes(limitDatetime.getUTCMinutes() + 5);
        if (utilDate.getDatetimeFormatLong(limitDatetime) > checkDatetime) {
          alert("現在時刻+5分以前の日時は指定できません。");
          return false;
        }
      }
      if (!window.confirm("自動更新を開始します。よろしいですか？")) {
        return false;
      }

      // 保存処理
      await updateFunc();
      await cancelUpdateDialog();
    };

    // タイマーで自動更新を開始する
    const updateFunc = async () => {
      // 次回更新時刻を取得
      store.dispatch("loadingIcon/showIcon");

      let start_update = null;
      if (state.updateMode == 1) {
        start_update = new Date();
        start_update.setMinutes(start_update.getUTCMinutes() + 5);
        start_update = utilDate.getDatetimeFormatLong(start_update) + ":00";
      } else if (state.updateMode == 2) {
        start_update =
          state.updateDate +
          " " +
          ("00" + state.updateHour).slice(-2) +
          ":" +
          ("00" + state.updateMinute).slice(-2) +
          ":00";
      }

      const params = {
        id: state.uContent.id,
        shop_site_id: state.uContent.shop_site_id,
        site_content_id: state.uContent.site_content_id,
        is_auto: true,
        current_mode: setting.mode_timer,
        start_update: start_update,
        next_update_set: true,
        time_zone: state.uContent.time_zone,
      }; // current_modeは1固定(setting.mode_timer)
      const response = await updateContentsRepository
        .update_next(params)
        .then((response) => {
          if (response.data) {
            // データ追加OK
            return response.data;
          }
        })
        .catch((error) => {
          throw (
            "ERROR:working/index.vue/updateButton UpdateContentsRepository.update_next (" +
            error +
            ")"
          );
        });
      store.dispatch("loadingIcon/hideIcon");
      if (!response.result) {
        const s_name =
          state.siteContent.name + "（" + state.shopSite.sites_name + "）";
        alert(
          "次回の更新時刻が取得できませんでした。\n" +
            "「" +
            s_name +
            "」" +
            "の更新設定に誤りがないか確認して下さい。"
        );
        return false;
      }
      state.uContent.id = response.update_content_id;

      // 更新を開始した時、画面を閉じる
      await router.push({
        name: "synchro-contents",
      });
    };

    // 設定ボタンクリック
    const settingButton = async () => {
      if (state.uContent.time_zone.length == 0) {
        state.time_zone_list = [];
      } else {
        state.time_zone_list = state.uContent.time_zone.split(",");
      }
      state.is_random = state.uContent.is_random;
      // setting.next_update_random_site_idsの配列に、state.uContent.site_idが含まれている時
      if (
        setting.next_update_random_site_ids.includes(state.uContent.site_id)
      ) {
        state.is_random_enable = false;
      }

      state.settingDialog = true;
    };

    // 設定ダイアログ閉じる
    const cancelSettingDialog = () => {
      state.time_zone_list.splice(0);
      state.is_random = false;
      state.settingDialog = false;
    };

    // 設定ダイアログ保存
    const onSubmitSettingDialog = async () => {
      set(state.uContent, "time_zone", state.time_zone_list.join());
      set(state.uContent, "is_random", state.is_random);
      await saveButton(false, true);

      cancelSettingDialog();
    };

    // 更新時間帯の数字クリック時
    const onClickTimeZone = (num) => {
      const result = state.time_zone_list.indexOf(num);
      if (result === -1) {
        state.time_zone_list.push(num);
      } else {
        state.time_zone_list.splice(result, 1);
      }
    };

    // 一覧に戻る
    const onBack = async () => {
      router.push({ name: "synchro-contents" });
    };

    return {
      setting,
      utilDate,
      selectITem,
      refTabItemComponent,
      refTabs,
      refForm,
      tabs,
      refAppBar,
      radioItems,
      ...toRefs(state),
      setOverlay,
      setDataTabs,
      saveButton,
      settingButton,
      cancelSettingDialog,
      onSubmitSettingDialog,
      onClickTimeZone,
      updateButton,
      cancelUpdateDialog,
      onSubmitUpdateDialog,
      updateFunc,
      menu: false,
      required: (value) => value != null,
      onBack,
    };
  },
});
</script>

<style scoped>
.shop_site_span_fixed {
  font-size: 10.5px;
  background-color: #fafafa;
  word-break: break-all;
}
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.select_item {
  width: 4em;
}
.time_zone_box {
  display: inline-block;
}
</style>
