<template>
  <div>
    <!-- 媒体登録ダイアログ -->
    <v-card>
      <v-card-text class="scrollable-content">
        <ValidationObserver ref="observer">
          <h3>日付変更時刻</h3>
          <div class="mt-2 mb-2"></div>
          <p class="pl-2">
            ここで指定した時刻によって、<strong>スケジュール画面の表示日付とシフト時刻設定幅</strong>および<strong>勤務ステータス更新画面の表示日付</strong>が変わります。<br />
            例えば、7時に設定するとスケジュール画面のシフト時刻設定幅が開始時刻7:00〜、終了時刻〜07:00となり、スケジュール画面・勤務ステータス更新画面は、AM7時を過ぎるまで前日が表示されます。
          </p>
          <v-container fluid>
            <v-row>
              <v-col cols="6" sm="3">
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  name="日付変更時刻"
                  rules=""
                >
                  <v-select
                    v-model="items.basic.dateline"
                    :items="dateline_options"
                    no-data-text="時刻を選択"
                    :error-messages="errors"
                    :success="valid"
                    outlined
                    label="時刻を選択"
                    dense
                  ></v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <p class="mb-0">【注意】</p>
            <ul>
              <li>
                ①時刻設定の初期値について<br />
                ヤトイテでは初期値を<strong>"6時"</strong>としています。特に差し支えなければ、そのままご利用ください。<br />
                <strong>"6時"</strong
                >以外を指定する際、特別な事情がない限りは、貴店の閉店時刻（又は閉店から開店までの間の時刻）を指定してください。
              </li>
              <li>
                ②スケジュール画面でのシフト設定について<br />
                <span class="text-red"
                  >更新先の媒体で設定できない時刻をヤトイテのスケジュール画面で入力しないでください。</span
                >更新時、警告またはエラーになる場合があります。媒体によっては、直近の時間を選択しエラーを回避します。<br />
                逆に、媒体からシフトを取り込む時、ヤトイテのシフト時間設定幅と合わない場合、開始時刻または終了時刻を前後調整して取込します。
              </li>
              <li>
                ③日付変更時刻の途中変更について<br />
                運用途中で時刻を変更する場合は、変更後の時刻を跨いだシフトが残っていないか確認してください。もしシフトが残っていた場合、スケジュールが正常に更新されない場合があります。
              </li>
            </ul>
          </v-container>
          <h3>更新時間帯</h3>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <p>
                  更新する時間帯をONにしてください。ここで設定した内容は、一部を除き(※1)すべてのコンテンツの更新時刻に影響します。OFFにした時間帯は、更新コンテンツ画面で更新時刻に設定していても更新されません。
                </p>
                <div
                  v-for="num in time_zone_default_list"
                  :key="num"
                  class="time_zone_box"
                >
                  <v-btn
                    :color="
                      items.basic.timezone.find((v) => v == num)
                        ? `primary`
                        : `grey lighten-3`
                    "
                    small
                    elevation="0"
                    class="pa-0 mr-1 mb-1"
                    @click="onClickTimeZone(num)"
                    >{{ num }}時</v-btn
                  >
                </div>
                <p>
                  ※1・・・スケジュールの自動更新についてはこちらの更新時間帯に影響されず、所定の時刻で更新されます。（毎日6時半〜7時に更新、エステ魂のみ更新時刻指定可能）
                </p>
              </v-col>
            </v-row>
          </v-container>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions>
        <v-container fluid>
          <v-row justify="end">
            <v-col cols="6" sm="3">
              <general-button btn_type="info" btn_block @click-event="onSubmit"
                ><template v-slot:icon
                  ><v-icon left>mdi-content-save</v-icon></template
                >保存</general-button
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { reactive, toRefs, defineComponent } from "@vue/composition-api";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import { repositoryFactory } from "@/repository/repositoryFactory";

export default defineComponent({
  setup(_, ctx) {
    const ShopPropsRepository = repositoryFactory.get("shopProps");
    const state = reactive({
      items: {
        basic: {
          dateline: 0,
          timezone: [],
        },
      },
      dateline_options: [
        {
          value: 0,
          text: "0時",
        },
        {
          value: 1,
          text: "1時",
        },
        {
          value: 2,
          text: "2時",
        },
        {
          value: 3,
          text: "3時",
        },
        {
          value: 4,
          text: "4時",
        },
        {
          value: 5,
          text: "5時",
        },
        {
          value: 6,
          text: "6時",
        },
        {
          value: 7,
          text: "7時",
        },
        {
          value: 8,
          text: "8時",
        },
        {
          value: 9,
          text: "9時",
        },
        {
          value: 10,
          text: "10時",
        },
        {
          value: 11,
          text: "11時",
        },
        {
          value: 12,
          text: "12時",
        },
      ],
      time_zone_default_list: setting.time_zone_default.split(","),
    });

    // 保存処理
    const onSubmit = async () => {
      // 入力チェック
      const isValid = await ctx.refs.observer.validate();
      if (!isValid) {
        // エラー処理
      } else {
        let message = "変更を保存してよろしいですか？";
        if (state.items.basic.timezone.length === 0) {
          message =
            "更新時間帯が設定されていません。この店舗は更新できませんが、設定を保存してよろしいですか？";
        }
        if (!window.confirm(message)) {
          return false;
        }

        store.dispatch("loadingIcon/showIcon"); // ローディング表示
        const shop_id = await store.getters["shops/currentShop"].id;

        // DBの更新
        const params = [
          {
            shop_id: shop_id,
            key: "dateline",
            value: state.items.basic.dateline,
          },
          {
            shop_id: shop_id,
            key: "timezone",
            value: state.items.basic.timezone.join(","),
          },
        ];
        await ShopPropsRepository.save_all(params)
          .then((response) => {
            if (response.data) {
              // データ追加OK save_allを使った場合、storeの全propを更新しておく
              Object.keys(response.data).forEach(function (key) {
                store.dispatch("shops/setProp", {
                  "id": response.data[key].id,
                  "key": response.data[key].key,
                  "value": response.data[key].value,
                });
              });
            }
          })
          .catch((error) => {
            throw "ERROR:ShopPropsRepository.save_all (" + error + ")";
          });

        // 1秒待機してからローディングを非表示
        await new Promise((resolve) => setTimeout(resolve, 1000));

        store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
      }
    };

    const init = async () => {
      // 初期値設定
      state.items.basic.dateline = setting.default_dateline;
      state.items.basic.timezone = setting.time_zone_default.split(",");

      // storeから値読み込み
      const currentShop = await store.getters["shops/currentShop"];
      let shopData = currentShop.data;
      if (shopData.prop) {
        for (let j = 0; j < shopData.prop.length; j++) {
          switch (shopData.prop[j].key) {
            case "dateline":
              state.items.basic.dateline = Number(shopData.prop[j].value);
              break;
            case "timezone":
              state.items.basic.timezone =
                shopData.prop[j].value == ""
                  ? []
                  : shopData.prop[j].value.split(",");
              break;
          }
        }
      }
    };

    init();

    const onClickTimeZone = (num) => {
      const result = state.items.basic.timezone.indexOf(num);
      if (result === -1) {
        state.items.basic.timezone.push(num);
      } else {
        state.items.basic.timezone.splice(result, 1);
      }
    };

    return {
      setting,
      ...toRefs(state),
      onSubmit,
      onClickTimeZone,
    };
  },
});
</script>

<style scoped>
.time_zone_box {
  display: inline-block;
}
</style>
