<template>
  <div>
    <app-bar
      ref="refAppBar"
      @all_sync_button="allSyncButton"
      @all_clear_button="allClearButton"
      @list_init="listInit"
    ></app-bar>
    <div>
      <schedule-list-component :key="refresh"></schedule-list-component>
    </div>

    <!-- 一括更新ダイアログ -->
    <v-dialog
      v-model="allSyncDialog"
      scrollable
      persistent
      width="100%"
      max-width="640px"
    >
      <v-card :disabled="allSyncDialogDisabled">
        <v-card-title>
          <span class="text-h5">スケジュールの一括更新</span>
        </v-card-title>
        <v-card-text>
          <v-subheader class="pl-0">
            登録された内容で直ちに更新処理を行います。更新したい媒体にチェックして下さい。
          </v-subheader>
          <h3>媒体を選択</h3>
          <v-container>
            <v-row>
              <v-col cols="12">
                <!-- テーブル -->
                <v-data-table
                  :headers="allSyncHeaders"
                  :items="allSyncSiteList"
                  :mobile-breakpoint="0"
                  item-key="uk"
                  show-select
                  :single-select="false"
                  v-model="allSyncSelected"
                  :footer-props="{ 'items-per-page-options': [-1] }"
                  hide-default-footer
                  @click:row="rowClick"
                >
                  <template v-slot:no-data>
                    表示するデータがありません
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template v-slot:item.status="{ item }">
                    {{ item.status }}
                    &nbsp;
                    <v-progress-linear
                      v-if="item.status == '更新中'"
                      indeterminate
                      color="primary"
                    ></v-progress-linear>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
          <h3>更新オプション</h3>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  class="mt-0 mb-2"
                  v-model="forceSync"
                  label="スケジュールの全更新"
                  value="force_sync"
                  hide-details
                ></v-checkbox>
                <p>
                  更新先の媒体の負荷軽減のため、一度媒体に更新したスケジュールは二度と更新しない仕組みとしています。ただし、こちらにチェックすると一度更新したスケジュールも含めて一括更新します。※スケジュールが正しく更新できない場合等にお試し下さい。
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelAllSyncDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
              <v-col cols="6" sm="3">
                <general-button
                  btn_type="info"
                  btn_block
                  @click-event="onSubmitAllSyncDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-sync</v-icon></template
                  >一括更新</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- キャスト選択ダイアログ -->
    <v-dialog
      v-model="castDialog"
      scrollable
      persistent
      width="100%"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">キャスト選択</span>
        </v-card-title>
        <v-card-text>
          <v-subheader class="pl-0">
            スケジュールを一括でクリアしたいキャストを選択して下さい。週間パターンも同時にクリアし、AUTOは未設定に戻します。
          </v-subheader>
          <v-text-field
            v-model="castSearch"
            append-icon="mdi-magnify"
            label="キャスト名検索"
            single-line
            hide-details
          ></v-text-field>
          <v-data-table
            id="cast_table"
            :headers="castHeaders"
            :items="casts"
            :search="castSearch"
            item-key="id"
            show-select
            :single-select="false"
            v-model="castSelected"
            :mobile-breakpoint="0"
            :items-per-page="50"
            :footer-props="{
              'items-per-page-options': [50, 100, -1],
              'items-per-page-text': '表示件数',
            }"
            @click:row="rowCastClick"
          >
            <!-- eslint-disable-next-line -->
                    <template v-slot:footer.page-text="props">
              {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
                props.itemsLength
              }}件
            </template>
            <!-- eslint-disable-next-line -->
                    <template v-slot:item.image="{ item }">
              <v-avatar class="ma-1">
                <v-img :src="setting.cdn + item.image" />
              </v-avatar>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelCastDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
              <v-col cols="6" sm="3">
                <general-button
                  btn_type="info"
                  btn_block
                  @click-event="onSubmitAllClearDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-eraser</v-icon></template
                  >一括クリア</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  toRefs,
  defineComponent,
  set,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import { useRouter } from "@/utils";
import store from "@/store/index.js";
import utilDate from "@/common/utilDate.js";
import ScheduleListComponent from "./scheduleList";
import appBar from "./appBar.vue";
import utilFunc from "@/common/utilFunc.js";
import setting from "@/common/setting.js";

export default defineComponent({
  components: {
    appBar,
    ScheduleListComponent,
  },
  setup(props, ctx) {
    const SynchroRepository = repositoryFactory.get("synchro");
    const ShopSitesRepository = repositoryFactory.get("shopSites");
    const castsRepository = repositoryFactory.get("casts");
    const SchedulesRepository = repositoryFactory.get("schedules");

    const refresh = ref();
    const refAppBar = ref();
    const forceSync = ref(false);
    const { router } = useRouter();

    const state = reactive({
      // 一括更新ボタン関連
      allSyncDialog: false,
      allSyncDialogDisabled: false,
      allSyncSiteList: [],
      allSyncSelected: [],
      allSyncHeaders: [
        {
          text: "サイト名",
          value: "site_name",
          sortable: false,
        },
        {
          text: "ステータス",
          value: "status",
          sortable: false,
        },
      ],
      castDialog: false,
      casts: [],
      castSearch: "",
      castSelected: [],
      castHeaders: [
        {
          text: "写真",
          value: "image",
          sortable: false,
          align: "center",
          width: 60,
        },
        {
          text: "キャスト名",
          value: "name",
          sortable: false,
          class: "td_name",
        },
        { text: "公開/非公開", value: "enable", sortable: false },
      ],
    });

    const listInit = async () => {
      refresh.value++;
    };

    const allSyncButton = async () => {
      // 更新サイト一覧取得
      state.allSyncSiteList.splice(0);
      state.allSyncSelected.splice(0);
      const params = new URLSearchParams();
      params.append("shop_id", store.getters["shops/currentShop"].id);
      await ShopSitesRepository.list_getschedule(params)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              const data = {
                uk: response.data[key].id, // shop_site_id
                site_content_id: response.data[key].site_content_id,
                site_name:
                  response.data[key].sites_name +
                  (JSON.parse(response.data[key].config).memo != ""
                    ? " [ " + JSON.parse(response.data[key].config).memo + " ] "
                    : ""),
                status: null,
                site_id: response.data[key].site_id,
              };
              state.allSyncSiteList.push(data);
              state.allSyncSelected.push(data);
            });
          }
        })
        .catch((error) => {
          throw "ERROR:データ取得エラー (" + error + ")";
        });

      state.allSyncDialog = true;
    };

    const allClearButton = async () => {
      // キャスト選択ダイアログ表示
      await getCastList();
      state.castDialog = true;
    };

    const rowClick = (item, row) => {
      row.select(!row.isSelected);
    };

    const cancelAllSyncDialog = async () => {
      state.allSyncDialog = false;
    };

    const onSubmitAllSyncDialog = async () => {
      // ここに更新処理
      if (
        !window.confirm(
          "選択したコンテンツを更新を開始します。よろしいですか？"
        )
      ) {
        return false;
      }
      // ラベル表示クリア
      for (let i = 0; i < state.allSyncSiteList.length; i++) {
        set(state.allSyncSiteList[i], "status", "");
      }
      // 順次更新処理を行う
      state.allSyncDialogDisabled = true;
      store.dispatch("loadingIcon/showIcon");

      // ラベル表示
      for (let i = 0; i < state.allSyncSelected.length; i++) {
        set(state.allSyncSelected[i], "status", "処理待ち");
      }
      // 順次処理する
      for (let i = 0; i < state.allSyncSelected.length; i++) {
        set(state.allSyncSelected[i], "status", "更新中");

        // 更新処理
        let params = new URLSearchParams();
        const shop_dateline = store.getters["shops/dateline"];
        params = {
          shop_id: store.getters["shops/currentShop"]["id"],
          shop_site_id: state.allSyncSelected[i].uk,
          work_date: utilDate.getDateFormatLong(
            utilDate.getToday(0, shop_dateline)
          ),
          force_sync: forceSync.value == "force_sync",
          site_id: state.allSyncSiteList[i].site_id,
        };
        let result = false;

        // スケジュールを更新する
        result = await SynchroRepository.sync_schedule(params)
          .then((response) => {
            if (response.data) {
              if (response.data.status) {
                return true;
              }
            } else {
              alert(response.data.message);
              return false;
            }
          })
          .catch((error) => {
            throw (
              "ERROR:Schedule/index.vue/onSubmitAllSyncDialog SynchroRepository.sync_schedule (" +
              error +
              ")"
            );
          });

        if (result) {
          set(state.allSyncSelected[i], "status", "更新完了");
        } else {
          set(state.allSyncSelected[i], "status", "更新失敗");
        }
      }

      store.dispatch("loadingIcon/hideIcon");
      state.allSyncDialogDisabled = false;

      // await cancelAllSyncDialog();
      alert("更新処理が完了しました");
    };

    const init = async () => {
      store.dispatch("loadingIcon/showIcon"); // ローディング表示

      // 店舗チェック falseの場合ログアウトになる
      const currentShop = store.getters["shops/currentShop"];
      const shopData = await utilFunc.checkShop(currentShop.id, router);
      if (!shopData) return;
      // store.dispatch("shops/updateCurrentShop", shopData);

      // キャストチェック(キャストが不在の場合、noCastはFALSE)
      const cast_count = await castsRepository
        .get_count(store.getters["shops/currentShop"].id)
        .then((response) => {
          if (response.data) {
            return response.data.count;
          }
        })
        .catch((error) => {
          throw (
            "ERROR:schedule/index.vue/init castsRepository.list (" + error + ")"
          );
        });
      refAppBar.value.setAllSyncBtnDisable(cast_count == 0);

      store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };

    init();

    const rowCastClick = (item, row) => {
      row.select(!row.isSelected);
    };

    const getCastList = async () => {
      // キャスト検索用リスト生成
      if (state.casts.length > 0) {
        return;
      }

      const params = new URLSearchParams();
      params.append("shop_id", store.getters["shops/currentShop"].id);
      await castsRepository
        .list(params)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              state.casts.push({
                id: response.data[key].id,
                name: response.data[key].name,
                image: response.data[key].image,
                enable: response.data[key].is_enable ? "公開" : "非公開",
              });
            });
          }
        })
        .catch((error) => {
          throw "ERROR:castsRepository.list (" + error + ")";
        });
    };

    const cancelCastDialog = () => {
      state.castDialog = false;
    };

    const onSubmitAllClearDialog = async () => {
      // ここに更新処理
      if (
        !window.confirm(
          "選択したキャストのスケジュールを削除します。よろしいですか？"
        )
      ) {
        return false;
      }
      // 選択キャストのスケジュールを削除する
      store.dispatch("loadingIcon/showIcon");

      // 更新処理
      let params = new URLSearchParams();
      const shop_dateline = store.getters["shops/dateline"];
      params = {
        shop_id: store.getters["shops/currentShop"]["id"],
        clear_date: utilDate.getDateFormatLong(
          utilDate.getToday(0, shop_dateline)
        ),
        cast_ids: state.castSelected.map((item) => item.id),
      };
      let result = false;

      // スケジュールを更新する
      result = await SchedulesRepository.all_clear(params)
        .then((response) => {
          if (response.data) {
            if (response.data.result) {
              return true;
            }
          } else {
            alert(response.data.message);
            return false;
          }
        })
        .catch((error) => {
          throw "ERROR:scheduleRepository.all_clear (" + error + ")";
        });

      if (result) {
        refresh.value = Date();
        state.castSelected.splice(0);
      }

      store.dispatch("loadingIcon/hideIcon");
      state.castDialog = false;
    };

    return {
      setting,
      listInit,
      refAppBar,
      refresh,
      forceSync,
      ...toRefs(state),
      allSyncButton,
      allClearButton,
      rowClick,
      cancelAllSyncDialog,
      onSubmitAllSyncDialog,
      onSubmitAllClearDialog,
      rowCastClick,
      getCastList,
      cancelCastDialog,
    };
  },
});
</script>

<style scoped>
.x-scroll-hidden {
  max-width: 100%;
  overflow-x: scroll;
  /* IE, Edge 対応 */
  -ms-overflow-style: none;
  /* Firefox 対応 */
  scrollbar-width: none;
}
/* Chrome, Safari 対応 */
.x-scroll-hidden::-webkit-scrollbar {
  display: none;
}
</style>
