<template>
  <div>
    <v-app-bar flat color="white" class="x-scroll">
      <div class="pa-2">
        <v-btn
          class="info font-weight-bold"
          :disabled="allSyncBtnDisabled"
          @click="onAllSyncButton"
          ><v-icon left>mdi-sync</v-icon
          ><span class="d-none d-sm-flex">一括更新</span
          ><span class="d-sm-none">更新</span></v-btn
        >
      </div>
      <div class="pa-2">
        <v-btn
          class="info font-weight-bold"
          :disabled="allSyncBtnDisabled"
          @click="onAllClearButton"
          ><v-icon left>mdi-eraser</v-icon
          ><span class="d-none d-sm-flex">一括クリア</span
          ><span class="d-sm-none">クリア</span></v-btn
        >
      </div>
      <div class="pa-2">
        <esutama-timer-set-component></esutama-timer-set-component>
      </div>
      <v-spacer></v-spacer>
      <div class="pa-2">
        <site-get-schedule-component
          class="ml-auto"
          :btn_disable="allSyncBtnDisabled"
          @get_schedule="getSchedule"
        ></site-get-schedule-component>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import { ref, reactive, toRefs } from "@vue/composition-api";
import SiteGetScheduleComponent from "./synchro/siteGetSchedule.vue";
import EsutamaTimerSetComponent from "./synchro/esutamaTimerSet.vue";

export default {
  components: {
    SiteGetScheduleComponent,
    EsutamaTimerSetComponent,
  },
  props: ["is_disable"],
  setup(props, ctx) {
    const allSyncBtnDisabled = ref(true);

    const onAllSyncButton = async () => {
      ctx.emit("all_sync_button");
    };

    const onAllClearButton = async () => {
      ctx.emit("all_clear_button");
    };

    const getSchedule = async () => {
      ctx.emit("list_init");
    };

    const setAllSyncBtnDisable = (disabled) => {
      allSyncBtnDisabled.value = disabled;
    };

    return {
      allSyncBtnDisabled,
      SiteGetScheduleComponent,
      EsutamaTimerSetComponent,
      onAllSyncButton,
      getSchedule,
      setAllSyncBtnDisable,
      onAllClearButton,
    };
  },
};
</script>
