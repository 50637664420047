// 共通で使用する定数定義
const setting = {
  // ソフトウェアバージョン 日付-時刻の数値8桁ハイフン数値6桁で表す
  system_version: "20241231-000001",

  // リトライの回数
  axios_retry_count: 3,

  //
  cdn: process.env.VUE_APP_CDN + "/",

  // 一週間の曜日
  weekday: ["日", "月", "火", "水", "木", "金", "土"],

  // 日付変更時刻
  sys_dateline: 6,
  default_dateline: 6, // デフォルトの日付変更時刻

  // キャスト取込モード
  synchro_cast_mode_new: "new",
  synchro_cast_mode_update: "update",
  synchro_cast_action_no: "no_action",
  synchro_cast_action_new: "new_cast",
  synchro_cast_action_update: "update_cast",
  synchro_cast_action_synchro: "synchro_cast",

  // 週間スケジュール画面で表示する全日数
  schedule_days: 14,
  // 週間スケジュール画面で横幅480以下の時に表示する日数
  schedule_0_480_show_days: 2,
  // 週間スケジュール画面で横幅480～600の時に表示する日数
  schedule_480_600_show_days: 3,
  // 週間スケジュール画面で横幅600～960の時に表示する日数
  schedule_600_960_show_days: 5,
  // 週間スケジュール画面で横幅960以上の時に表示する日数
  schedule_960_1600_show_days: 10,
  // 週間スケジュール画面で横幅960以上の時に表示する日数
  schedule_1600_show_days: 14,
  // 週間スケジュール画面で前・次ボタンで移動する日数
  schedule_prev_next_days: 7,

  // サイトタイプ
  site_types: [
    { type: 1, name: "営業" },
    { type: 2, name: "求人" },
    { type: 3, name: "SNS" },
  ],
  site_type_sales: 1,
  site_type_recruit: 2,
  site_type_sns: 3,

  // サイトカラー設定
  shop_site_colors: [
    "#000000FF",
    "#F44336FF",
    "#673AB7FF",
    "#03A9F4FF",
    "#4CAF50FF",
    "#FFEB3BFF",
    "#FF5722FF",
    "#E91E63FF",
    "#3F51B5FF",
    "#00BCD4FF",
    "#8BC34AFF",
    "#FFC107FF",
    "#795548FF",
    "#9C27B0FF",
    "#2196F3FF",
    "#009688FF",
    "#CDDC39FF",
    "#FF9800FF",
    "#607D8BFF",
  ],

  // APIキーのタイプ
  api_key_type_hp: "hp",

  // テンプレートに登録できる画像枚数
  template_image_number: 10,

  // no_image画像
  no_image: "@/assets/images/no_image.jpg",

  // 更新コンテンツ時間指定の初期表示行
  default_update_rows: 10,
  default_update_rows_other: 0,

  // 更新コンテンツのモード
  mode_specify: 0, // 時間指定更新
  mode_timer: 1, // タイマー更新

  // エステ魂のsite_id
  esutama_site_id: [4, 23],

  // エステラブのsite_id
  eslove_site_id: 17,

  // 出勤ステータス
  work_status_working: 0,
  work_status_before: 1,
  work_status_after: 2,

  // 即姫モード
  working_mode_label: [
    { value: 0, text: "未設定" },
    { value: 1, text: "待機中" },
    { value: 2, text: "接客中" },
    { value: 3, text: "受付終了" },
  ],
  working_mode_none: 0,
  working_mode_standby: 1,
  working_mode_service: 2,
  working_mode_end: 3,

  // update_contentsテーブルのtime_zoneカラム, shop_propsテーブルのキーtimezone デフォルト値
  time_zone_default:
    "0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23",

  time_zone_default_castup: "0,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23",

  // コンテンツ種別
  content_id_cast: 1,
  content_id_schedule: 2,
  content_id_other: 3,
  content_id_news: 4,
  content_id_event: 5,
  content_id_sale: 6,
  content_id_up: 7,
  content_id_blog: 8,
  content_id_reqruit: 9,
  content_id_working: 10,
  content_id_castup: 11,
  content_id_post: 12,

  // cast_props キー ： 項目名 定義
  cast_props_common: {
    "cast__basic__name": {
      text: "名前",
      rules: "required|max:20",
      counter: "20",
    },
    "cast__basic__katakana": {
      text: "カタカナ",
      rules: "max:40",
      counter: "40",
    },
    "cast__basic__hiragana": {
      text: "ひらがな",
      rules: "max:40",
      counter: "40",
    },
    "cast__basic__age": {
      text: "年齢",
      rules: "numeric|max:2",
      counter: "2",
    },
    "cast__basic__tall": {
      text: "身長",
      rules: "numeric|max:3",
      counter: "3",
    },
    "cast__basic__bust": {
      text: "バスト",
      rules: "numeric|max:3",
      counter: "3",
    },
    "cast__basic__waist": {
      text: "ウエスト",
      rules: "numeric|max:3",
      counter: "3",
    },
    "cast__basic__hip": {
      text: "ヒップ",
      rules: "numeric|max:3",
      counter: "3",
    },
    "cast__basic__cup": {
      text: "カップ",
      rules: "",
      counter: "",
    },
    "cast__basic__comment_by_shop": {
      text: "お店コメント",
      rules: "",
      counter: "",
    },
    "cast__basic__comment_by_cast": {
      text: "キャストコメント",
      rules: "",
      counter: "",
    },
    "cast__basic__catch_copy": {
      text: "キャッチコピー",
      rules: "max:30",
      counter: "30",
    },
    "cast__basic__blood_type": {
      text: "血液型",
      rules: "",
      counter: "10",
    },
    "cast__basic__sign": {
      text: "星座",
      rules: "",
      counter: "",
    },
    "cast__basic__style": {
      text: "スタイル",
      rules: "",
      counter: "",
    },
    "cast__basic__type": {
      text: "タイプ",
      rules: "",
      counter: "",
    },
  },

  // cast_props キー ： 項目名 定義
  cast_props_common_comment: {
    "cast__basic__comment_by_shop": {
      text: "お店コメント",
      rules: "",
      counter: "",
    },
    "cast__basic__comment_by_cast": {
      text: "キャストコメント",
      rules: "",
      counter: "",
    },
  },

  // 媒体個別項目キー用プレフィックス
  // エステ魂のエステ歴の場合：'cast__only__****__estama__experience'となる。****にはshop_sitesテーブルのIDがセットされる。
  cast_props_only: "cast__only",

  // 媒体個別項目キー用プレフィックス
  // エステ魂の質問1の場合：'cast__qa__****__estama__qa_a1'となる。****にはshop_sitesテーブルのIDがセットされる。
  cast_props_qa: "cast__qa",

  // 共通のキャスト画像に登録できる画像枚数
  cast_image_number: 20,
  // 共通を表すshop_site_id
  common_shop_site_id: 0,

  // オートフラグの対象となる契約終了日
  auto_flag_end_date: "2099-12-31",

  // プラン種別ID
  plan_type_id_basic: "BP",
  plan_type_id_option: "OP",

  // プランの種別
  plan_type_label: [
    { value: "BP", text: "基本契約" },
    { value: "OP", text: "オプション" },
  ],

  // オプション判別用
  plan_code_hp_link: "OP002", // ホームページ連携オプション
  plan_code_castup_base: "OP003", // キャスト上位更新オプション
  plan_code_castup_1: "OP003_1", // キャスト上位更新オプション(1-5分)
  plan_code_castup_2: "OP003_2", // キャスト上位更新オプション(15-30分)
  plan_code_castup_3: "OP003_3", // キャスト上位更新オプション(30-60分)

  plan_code_castup_1_name: "キャスト上位更新オプション(1-5分)", // キャスト上位更新オプション(1-5分)
  plan_code_castup_2_name: "キャスト上位更新オプション(15-30分)", // キャスト上位更新オプション(15-30分)
  plan_code_castup_3_name: "キャスト上位更新オプション(30-60分)", // キャスト上位更新オプション(30-60分)
  plan_code_castup_free: "通常", // 通常（無料）

  min_castup_num: 1, // キャスト上位更新 有料オプションの最小人数
  max_castup_num: 2, // キャスト上位更新 有料オプションの最大人数
  max_castup_num_free: 1, // キャスト上位更新 無料オプションの最大人数

  // ホームページ連携機能関連
  site_category_a_type: "A", // サイトカテゴリAタイプ(全国媒体)
  site_category_b_type: "B", // サイトカテゴリBタイプ(エステ魂)
  site_category_b_plus_type: "B+", // サイトカテゴリB+タイプ(エステ魂<最短更新>)
  site_category_c_type: "C", // サイトカテゴリCタイプ(エステラブ)
  site_category_hp_link: "HP", // サイトカテゴリホームページ連携
  site_category_t_type: "T", // サイトカテゴリTタイプ(オフィシャル系)
  site_category_sns: "SNS", // サイトカテゴリTタイプ(SNS系)

  // 勤務ステータスの個別設定が必須になる媒体コンテンツID
  working_status_required_config_ids: [47, 48],

  // 勤務ステータスの個別設定項目の定義
  working_status_config: [
    {
      site_content_id: 47,
      items: {
        "update_type": {
          text: "更新タイプ",
          rules: "required",
          counter: "",
        },
        "gidi_discount_time": {
          text: "割引価格(分)",
          rules: "required|numeric|min_value:0|max_value:999",
          counter: "",
        },
        "gidi_discount_price": {
          text: "割引価格(円)",
          rules: "required|numeric|min_value:0|max_value:9999999",
          counter: "",
        },
        "gidi_dlt": {
          text: "割引有効時間",
          rules: "required",
          counter: "",
        },
        "gidi_info_text": {
          text: "割引の補足説明",
          rules: "",
          counter: "",
        },
        "gidi_list_flag": {
          text: "新着に掲載",
          rules: "required",
          counter: "",
        },
      },
    },
    {
      site_content_id: 48,
      items: {
        "update_type": {
          text: "更新タイプ",
          rules: "required",
          counter: "",
        },
        "girl_notime_list_flag": {
          text: "新着に掲載",
          rules: "required",
          counter: "",
        },
      },
    },
  ],

  // キャスト並び替え非対応site_id
  cast_sort_disable_site_ids: [22],

  // コンテンツの更新時間ランダム最大幅(分)
  max_random_minutes: 9,
  // ランダム更新を有効にできる最低更新間隔(分) 指定時間以上はランダム更新ができる
  enable_min_update_random: 11,
  // ランダム更新を強制的に設定するサイトID
  next_update_random_site_ids: [4, 17],

  // SNS関連サイトID
  sns_site_ids: [24],
};

export default setting;
