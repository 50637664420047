<template>
  <div>
    <h3>このポストに返信できるアカウント</h3>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="12" md="6">
          <v-select
            v-model="item.reply_settings"
            name="photo_nums"
            :items="replaySettingItems"
            no-data-text="返信できるアカウント"
            label="返信できるアカウント"
            success
            outlined
            hint="このポストに返信できるアカウントを選択します。@ポストされたアカウントは常に返信できます。[必須項目]"
            persistent-hint
            :rules="require_rule"
            class="mt-2"
          ></v-select>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  set,
  reactive,
  toRefs,
  defineComponent,
  onMounted,
} from "@vue/composition-api";

export default defineComponent({
  props: ["template_config"],
  setup(props, ctx) {
    const state = reactive({
      item: {
        reply_settings: "mentionedUsers",
      },
    });

    const replaySettingItems = [
      { value: "everyone", text: "全員" },
      { value: "following", text: "あなたがフォローしているアカウント" },
      { value: "mentionedUsers", text: "あなたが＠ポストしたアカウントのみ" },
    ];

    const getData = async () => {
      const config = props.template_config;
      for (const [key, value] of Object.entries(config)) {
        if (key == "reply_settings") {
          set(state.item, "reply_settings", value);
        }
      }
    };

    onMounted(async () => {
      // データセット
      if (props.template_config) {
        await getData(props.template_config);
      }
    });

    const saveConfig = async () => {
      return { "reply_settings": state.item.reply_settings };
    };

    return {
      ...toRefs(state),
      replaySettingItems,
      require_rule: [(v) => !!v || "必須選択項目です"],
      saveConfig,
    };
  },
});
</script>
