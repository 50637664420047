<template>
  <!-- テンプレート登録ダイアログ -->
  <div>
    <ValidationObserver ref="observer">
      <h3 id="body_head">本文</h3>
      <div class="mt-2">Xに投稿できる文字は全角で140文字です。</div>
      <div>
        <span :class="tweetCount > 140 ? 'text-red' : ''"
          >現在の文字数: {{ tweetCount }} / 140文字まで可</span
        >
      </div>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors, valid }"
              name="本文"
              rules="required|x_post_length_base:140"
            >
              <v-textarea
                v-model="body"
                name="body"
                :error-messages="errors"
                :success="valid"
                label="本文"
                rows="8"
                outlined
                background-color="white"
                hint="半角文字:0.5文字、全角文字:1文字、改行:0.5文字、URL:11.5文字として扱います。"
                persistent-hint
              />
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-container>
      <!-- 画像選択コンポーネント -->
      <tabImageComponent
        ref="refTabImageComponent"
        :template_config="template_config"
        :is_contain="true"
      ></tabImageComponent>
      <!-- 1回のポストで掲載する画像枚数 -->
      <xPhotoCountComponent
        ref="refXPhotoCountComponent"
        :template_config="template_config"
      ></xPhotoCountComponent>
      <!-- このポストに返信できるアカウント -->
      <xReplySettingsComponent
        ref="refXReplySettingsComponent"
        :template_config="template_config"
      ></xReplySettingsComponent>
    </ValidationObserver>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  defineComponent,
  onMounted,
  watch,
} from "@vue/composition-api";
import setting from "@/common/setting.js";
import xPhotoCountComponent from "./itemComponent/x_photo_count";
import xReplySettingsComponent from "./itemComponent/x_reply_settings";
import utilDate from "@/common/utilDate.js";
import utilTime from "@/common/utilTime.js";
import twitterText from "twitter-text";
import tabImageComponent from "./itemComponent/tabImage";

export default defineComponent({
  components: {
    tabImageComponent,
    xPhotoCountComponent,
    xReplySettingsComponent,
  },
  props: ["template_config"],
  setup(props, ctx) {
    const state = reactive({
      title: "",
      body: "",
      tweetCount: 0,
    });

    const clearDialog = async () => {
      state.title = "";
      state.body = "";
    };

    const setData = async () => {
      // 各項目に値をセット
      const config = props.template_config;
      state.title = config.title_1000;
      state.body = config.body_10000;
    };

    onMounted(async () => {
      // 初期化
      await clearDialog();
      // データセット
      if (props.template_config) {
        await setData(props.template_config);
      }
    });

    // 保存前のチェック処理
    const checkValid = async () => {
      // 入力チェック エラー状態の部品が無いか調べる
      let isValid = await ctx.refs.observer.validate();

      let imageConfigIsValid = await ctx.refs.refTabImageComponent.checkValid();
      if (!imageConfigIsValid) {
        isValid = false;
      }

      if (!isValid) {
        isValid = false;
        // エラー処理
        const el = document.querySelectorAll(
          ".v-text-field.error--text, .v-textarea.error--text"
        );
        if (el.length > 0) {
          el[0].scrollIntoView();
        }
      }

      return isValid;
    };

    // 保存処理
    const onSubmit = async () => {
      // 保存処理
      const config = {};
      config["title_1000"] = state.title;
      config["body_10000"] = state.body;

      // 画像コンポーネントの設定を取得
      const tabImageConfig = await ctx.refs.refTabImageComponent.saveConfig();
      const xPhotoCountConfig =
        await ctx.refs.refXPhotoCountComponent.saveConfig();
      const xReplySettingsConfig =
        await ctx.refs.refXReplySettingsComponent.saveConfig();

      return JSON.stringify({
        ...config,
        ...tabImageConfig,
        ...xPhotoCountConfig,
        ...xReplySettingsConfig,
      });
    };

    // 値が変わった時に実行する処理
    const onBodyChange = (newValue, oldValue) => {
      const pt = twitterText.parseTweet(newValue);
      state.tweetCount = pt.weightedLength / 2;
    };
    watch(() => state.body, onBodyChange);

    // 返却オブジェクト定義
    return {
      setting,
      ...toRefs(state),
      checkValid,
      onSubmit,
      require_rule: [(v) => !!v || "必須選択項目です"],
    };
  },
});
</script>

<style scoped>
.editable-div {
  border: 1px solid #ccc;
  padding: 10px;
  min-height: 100px;
}

.highlight {
  background-color: yellow;
}
</style>
