<template>
  <!-- テンプレート登録ダイアログ -->
  <div>
    <ValidationObserver ref="observer">
      <h3>おすすめセラピストの選び方</h3>
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-select
              v-model="appeal_type"
              name="update_type"
              :items="appealTypeItem"
              no-data-text="選び方"
              label="選び方"
              success
              outlined
              hint="おすすめセラピストの選び方を指定[必須項目]"
              persistent-hint
              :rules="require_rule"
              class="mt-2"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
      <div v-show="appeal_type == 1" class="mini_comment">
        【説明】<br />
        媒体に登録されている全キャストからランダムに選択します。
      </div>
      <v-container fluid v-show="appeal_type == 2">
        <v-row>
          <v-col cols="12" class="mini_comment">
            【説明】<br />
            下記一覧にてチェックしたキャストからランダムに選択します。
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" sm="8">
            <p class="text-subtitle-2">
              ※ランダムにしたくない場合は、ひとりだけチェックしてください。
            </p>
          </v-col>
          <v-col cols="6" sm="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="（キーワード検索）"
              single-line
              hide-details
              clearable
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <!-- テーブル -->
            <v-data-table
              class="cast_table"
              :headers="headers"
              :items="desserts"
              :mobile-breakpoint="0"
              :search="search"
              item-key="id"
              show-select
              :single-select="false"
              v-model="selected"
              @click:row="rowClick"
              :items-per-page="10"
              :footer-props="{
                'items-per-page-options': [10, 20, 50, 100, -1],
                'items-per-page-text': '表示件数',
              }"
            >
              <template v-slot:no-data> 表示するデータがありません </template>
              <template v-slot:footer.page-text="props">
                {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
                  props.itemsLength
                }}件
              </template>
              <template v-slot:item.cast_name="{ item }"> <!-- eslint-disable-line -->
                <div>
                  <v-avatar class="ma-1 mr-3"
                    ><v-img :src="setting.cdn + item.image" /></v-avatar
                  >{{ item.cast_name }}
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </ValidationObserver>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  defineComponent,
  onMounted,
} from "@vue/composition-api";
import setting from "@/common/setting.js";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";

export default defineComponent({
  props: ["template_config"],
  setup(props, ctx) {
    const castsRepository = repositoryFactory.get("casts");
    const state = reactive({
      appeal_type: 1,
      search: "",
      headers: [
        {
          text: "キャスト",
          value: "cast_name",
          sortable: true,
        },
      ],
      desserts: [], // DataTablesレコード格納用
      selected: [],
    });

    const appealTypeItem = [
      { value: 1, text: "全キャストからランダムに選択する" },
      { value: 2, text: "チェックしたキャストからランダムに選択する" },
    ];

    const getCastList = async () => {
      // キャスト検索用リスト生成
      state.desserts.splice(0);
      const params = new URLSearchParams();
      params.append("shop_id", store.getters["shops/currentShop"].id);
      await castsRepository
        .list(params)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              state.desserts.push({
                id: response.data[key].id,
                cast_name: response.data[key].name,
                image: response.data[key].image,
              });
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:template/component/templateDialogComponent.vue/clearDialog castsRepository.list (" +
            error +
            ")"
          );
        });
    };

    const clearDialog = async () => {
      // キャストリスト取得
      await getCastList();

      state.appeal_type = 1;
    };

    const setData = async () => {
      // 各項目に値をセット
      const config = props.template_config;
      state.appeal_type = config.appeal_type ?? 1;
      if (state.appeal_type == 2) {
        state.desserts.forEach((item) => {
          if (config.appeal_casts.includes(item.id)) {
            state.selected.push(item);
          }
        });
      }
    };

    onMounted(async () => {
      // 初期化
      await clearDialog();
      // データセット
      if (props.template_config) {
        await setData(props.template_config);
      }
    });

    // 保存前のチェック処理
    const checkValid = async () => {
      // 入力チェック エラー状態の部品が無いか調べる
      const isValid = await ctx.refs.observer.validate();
      if (!isValid) {
        // エラー処理
        const el = document.querySelectorAll(
          ".v-text-field.error--text, .v-textarea.error--text"
        );
        if (el.length > 0) {
          el[0].scrollIntoView();
        }
      }

      return isValid;
    };

    // 保存処理
    const onSubmit = async () => {
      // 保存処理
      const config = {};
      config["appeal_type"] = state.appeal_type;
      if (state.appeal_type == 2) {
        config["appeal_casts"] = state.selected.map((item) => item.id);
      } else {
        config["appeal_casts"] = [];
      }

      return JSON.stringify({ ...config });
    };

    const rowClick = (item, row) => {
      row.select(!row.isSelected);
    };

    // 返却オブジェクト定義
    return {
      setting,
      appealTypeItem,
      ...toRefs(state),
      checkValid,
      onSubmit,
      rowClick,
      require_rule: [(v) => !!v || "必須選択項目です"],
    };
  },
});
</script>

<style scoped>
.mini_comment {
  padding: 0.5em;
  margin-bottom: 0.5em;
  border-radius: 0.5em;
  background-color: #eefaff;
}
</style>
