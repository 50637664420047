<template>
  <v-card
    elevation="1"
    :loading="loadingFlg"
    :class="`${isAlart ? 'bg_ng' : isError ? 'bg_gray' : 'bg_ok'}`"
  >
    <v-card-title class="small-title"
      ><v-icon>mdi-book-open-page-variant</v-icon
      >&nbsp;更新結果履歴（24時間以内の最新10件）<v-spacer></v-spacer>
      <div
        class="cursor-pointer mini text-v-blue bg_white pl-2 pr-2 rounded btn_hover"
        @click="goResultLog"
      >
        1週間分を見る
      </div>
    </v-card-title>
    <v-card-text class="pa-0">
      <!-- テーブル -->
      <v-data-table
        :headers="headers"
        :items="desserts"
        item-key="id"
        :mobile-breakpoint="0"
        :items-per-page="10"
        hide-default-footer
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:footer.page-text="props">
          {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
            props.itemsLength
          }}件
        </template>
        <template v-slot:body="{ items: desserts }">
          <tbody v-if="desserts.length > 0">
            <tr v-for="row in desserts" :key="row.index">
              <!-- 結果 -->
              <td class="text-center">
                <div v-if="row.result == 'ok'">
                  <v-icon class="pa-1" color="success">
                    mdi-check-circle-outline
                  </v-icon>
                </div>
                <div v-else>
                  <v-icon class="pa-1" color="error">
                    mdi-alert-circle-outline
                  </v-icon>
                </div>
              </td>
              <!-- 更新時刻 -->
              <td class="text-start">
                <div v-if="row.result == 'ok'">
                  <span class="success--text">
                    <!-- {{ utilDate.getDatetimeFormatLongDisplay(row.start_time) }} -->
                    {{ row.start_time }}
                  </span>
                </div>
                <div v-else class="error--text">
                  <span>
                    <!-- {{ utilDate.getDatetimeFormatLongDisplay(row.start_time) }} -->
                    {{ row.start_time }}
                  </span>
                </div>
              </td>
              <!-- コンテンツ名 -->
              <td class="text-start">
                <span class="text-subtitle-1">{{ row.site_content_name }}</span>
              </td>
              <!-- 媒体 -->
              <td class="text-start">
                <span
                  :style="row.color.length != 0 ? 'color:' + row.color : ''"
                  >{{ row.site_name }}</span
                >
              </td>
              <!-- カテゴリ -->
              <td class="text-start">
                <v-icon dense> {{ row.content_icon }} </v-icon
                ><span class="text-align-middle mini"
                  >&nbsp;{{ row.content_name }}</span
                >
              </td>
              <!-- メッセージ/補足 -->
              <td class="text-start">
                <span class="mini">{{ row.result_message }}</span>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td :colspan="headers.length" style="text-align: center">
                表示するデータがありません
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { reactive, toRefs, onMounted } from "@vue/composition-api";
import store from "@/store/index.js";
import { repositoryFactory } from "@/repository/repositoryFactory";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate.js";
import retryRequest from "@/common/retryRequest";

export default {
  setup(props, ctx) {
    const resultLogsRepository = repositoryFactory.get("resultLogs");

    const state = reactive({
      loadingFlg: false,
      isAlart: false,
      headers: [
        {
          text: "結果",
          value: "result",
          sortable: true,
          align: "center",
          width: 60,
        },
        {
          text: "更新時刻",
          value: "start_time",
          sortable: true,
          width: 140,
        },
        {
          text: "コンテンツ名",
          value: "site_content_name",
          sortable: true,
          class: "td_site_content_name",
        },
        {
          text: "媒体",
          value: "site_name",
          sortable: true,
          class: "td_site_name",
        },
        {
          text: "タイプ",
          value: "content_name",
          sortable: true,
          class: "td_content_name",
        },
        {
          text: "メッセージ ／ 補足情報",
          value: "result_message",
          sortable: true,
          class: "td_result_message",
        },
      ],
      desserts: [], // DataTablesレコード格納用
      isError: false,
    });

    const getResultLog = async () => {
      state.isError = false;
      state.loadingFlg = true;

      // 更新結果取得
      state.desserts.splice(0);
      const now = new Date();
      const str_now = utilDate.getDatetimeFormatLong(now);
      const str_yesterday = utilDate.getDatetimeFormatLong(
        new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - 1,
          now.getHours(),
          now.getMinutes(),
          now.getSeconds()
        )
      );
      const params = {
        shop_id: store.getters["shops/currentShop"].id,
        s_datetime: str_yesterday,
        e_datetime: str_now,
        filter: null,
        limit: 10,
      };
      state.isAlart = false;

      try {
        const response = await retryRequest(resultLogsRepository.list, params);
        if (response.data) {
          Object.keys(response.data).forEach(function (key) {
            const site_name =
              response.data[key].site_name +
              (response.data[key].memo
                ? "[" + response.data[key].memo + "]"
                : "");
            const st = response.data[key].start_time.split("T");
            state.desserts.push({
              "id": response.data[key].id ?? 0,
              "shop_id": response.data[key].shop_id,
              "shop_name": response.data[key].shop_name,
              "shop_site_id": response.data[key].shop_site_id,
              "site_name": site_name,
              "site_content_name": response.data[key].site_content_name,
              "content_id": response.data[key].content_id,
              "content_name": response.data[key].content_name,
              "content_icon": response.data[key].content_icon,
              "memo": response.data[key].memo,
              "color": response.data[key].color,
              "site_content_id": response.data[key].site_content_id,
              "result": response.data[key].result,
              "result_message": response.data[key].result_message,
              "start_time":
                st[0].replace(/-/g, "/") + " " + st[1].substring(0, 5),
            });
            if (response.data[key].result !== "ok") {
              state.isAlart = true;
            }
          });
        }
      } catch (error) {
        state.isError = true;
      } finally {
        state.loadingFlg = false;
      }
    };

    onMounted(() => {
      getResultLog();
    });

    const goResultLog = () => {
      ctx.root.$router.push("/result-log");
    };

    return {
      props,
      ...toRefs(state),
      utilDate,
      goResultLog,
    };
  },
};
</script>

<style scoped>
.small-title {
  font-size: 16px;
}
.bg_ok {
  background-color: #ddeeff;
}
.bg_ng {
  background-color: #ffeeee;
}
.bg_white {
  background-color: #ffffff;
}
.btn_hover:hover {
  background-color: #f4f7ff;
}
::v-deep .v-data-table table thead tr th,
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .td_site_content_name {
  min-width: 150px;
  width: 250px;
}
::v-deep .td_site_name {
  min-width: 200px;
  width: 250px;
}
::v-deep .td_content_name {
  min-width: 80px;
  width: 120px;
}
::v-deep .td_result_message {
  min-width: 250px;
}
</style>
