<template>
  <!-- テンプレート登録ダイアログ -->
  <div>
    <v-btn class="info font-weight-bold" @click="showDialog"
      ><v-icon left>mdi-plus-box</v-icon
      ><span class="d-none d-sm-flex">新規登録</span
      ><span class="d-sm-none">新規</span></v-btn
    >
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      width="100%"
      max-width="1200px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">テンプレート設定</span>
        </v-card-title>
        <v-card-text class="scrollable-content">
          <input type="hidden" v-model="template.id" />
          <ValidationObserver ref="baseObserver">
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="6">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="テンプレート名"
                    rules="required|max:255"
                  >
                    <v-text-field
                      v-model="template.name"
                      name="name"
                      :error-messages="errors"
                      :success="valid"
                      label="テンプレート名"
                      hint="テンプレート名を入力してください[必須項目]"
                      persistent-hint
                      outlined
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="template.content_format_id"
                    name="code"
                    :items="contentFormats"
                    no-data-text="フォーマットを選択して下さい"
                    label="使用フォーマット選択"
                    success
                    outlined
                    hint="使用するフォーマットを選択して下さい[必須項目]"
                    :rules="select_format"
                    @change="onChangeFormat"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </ValidationObserver>
          <!-- フォーマット -->
          <component
            v-bind:is="selectComponent"
            ref="refFormatComponent"
            :template_config="template.config"
          ></component>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
              <v-col cols="6" sm="3">
                <general-button
                  btn_type="info"
                  btn_block
                  @click-event="onSubmit"
                  ><template v-slot:icon
                    ><v-icon left>mdi-content-save</v-icon></template
                  >保存</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";

export default defineComponent({
  setup(props, ctx) {
    const templatesRepository = repositoryFactory.get("templates");
    const refFormatComponent = ref();
    const state = reactive({
      dialog: false, // ダイアログ表示用
      template: {
        id: 0,
        name: "",
        content_format_id: 0,
        config: {},
      },
      contentFormats: [],
      baseComponents: {},
      selectComponent: null,
    });

    const clearDialog = async () => {
      // 各種項目初期化
      state.template.id = 0;
      state.template.name = "";
      state.template.content_format_id = 0;
      state.template.config = {};
      state.selectComponent = null;
    };

    // 新規作成
    const showDialog = async () => {
      await store.dispatch("loadingIcon/showIcon"); // ローディング表示

      // コンテンツフォーマット取得
      state.contentFormats = store.getters["templates/getContentFormatList"];
      state.template.content_format_id = state.contentFormats[0].id;
      // フォーマット設定
      state.selectComponent =
        state.baseComponents[state.contentFormats[0].code];

      scrollReset();

      // ダイアログ開く
      state.dialog = true;

      // ローディング非表示
      await store.dispatch("loadingIcon/hideIcon");
    };

    const editDialog = async (templateId) => {
      // コンテンツフォーマット取得
      state.contentFormats = store.getters["templates/getContentFormatList"];
      // テンプレート読み込み
      state.template = await templatesRepository
        .get(templateId)
        .then((response) => {
          if (response.data) {
            // データ追加OK
            return {
              id: response.data.id,
              name: response.data.name,
              content_format_id: response.data.content_format_id,
              config: JSON.parse(response.data.config),
            };
          }
        })
        .catch((error) => {
          throw "ERROR:templateRepository.create (" + error + ")";
        });

      // フォーマット設定
      const getFormatCode = state.contentFormats.find(
        (v) => v.id == state.template.content_format_id
      ).code;
      state.selectComponent = state.baseComponents[getFormatCode];

      // ダイアログ開く
      state.dialog = true;
    };

    // 保存処理
    const onSubmit = async () => {
      // 入力チェック エラー状態の部品が無いか調べる
      const isValid = await ctx.refs.baseObserver.validate();

      if (!isValid) {
        // エラー処理
        const el = document.querySelectorAll(
          ".v-text-field.error--text, .v-textarea.error--text"
        );
        if (el.length > 0) {
          el[0].scrollIntoView();
        }
        return false;
      } else {
        // フォーマットコンポーネント側のチェック処理を行う
        const isComponentValid = await refFormatComponent.value.checkValid();
        console.log("isComponentValid", isComponentValid);
        if (!isComponentValid) {
          return false;
        }

        if (!window.confirm("保存してよろしいですか？")) {
          return false;
        }
        // ローディング表示
        store.dispatch("loadingIcon/showIcon");

        // フォーマットコンポーネント側の保存処理を先に行う
        const saveConfig = await refFormatComponent.value.onSubmit();

        // 保存処理
        const params = {
          id: state.template.id,
          shop_id: store.getters["shops/currentShop"].id,
          content_format_id: state.template.content_format_id,
          name: state.template.name,
          config: saveConfig,
          is_active: true,
        };

        // 新規か更新か
        if (state.template.id == 0) {
          // 新規
          await templatesRepository
            .create(params)
            .then((response) => {
              if (response.data) {
                // データ追加OK
                state.template.id = response.data.id; // idをセット
                store.dispatch("templates/addTemplate", {
                  id: response.data.id,
                  name: state.template.name,
                  image: saveConfig["image_0"],
                  content_format_id: state.template.content_format_id,
                  content_format_name: state.template.content_format_name,
                });
              }
            })
            .catch((error) => {
              throw "ERROR:templateRepository.create (" + error + ")";
            });
        } else {
          // 更新
          await templatesRepository
            .update(params)
            .then((response) => {
              if (response.data) {
                // データ追加OK
                store.dispatch("templates/updateTemplate", {
                  id: response.data.id,
                  name: state.template.name,
                  image: saveConfig["image_0"],
                  content_format_id: state.template.content_format_id,
                  content_format_name: state.template.content_format_name,
                });
              }
            })
            .catch((error) => {
              throw "ERROR:templateRepository.update (" + error + ")";
            });
        }

        // ローディング非表示
        store.dispatch("loadingIcon/hideIcon");

        // ダイアログを閉じる
        cancelDialog();
        ctx.emit("save");
      }
    };

    // ダイアログキャンセルボタン
    const cancelDialog = () => {
      state.dialog = false;
      ctx.refs.baseObserver.reset(); // 入力エラー表示クリア
      scrollReset();
      clearDialog();
    };

    const scrollReset = () => {
      setTimeout(() => {
        document.querySelector(".scrollable-content").scroll(0, 0);
      }, 200);
    };

    // フォーマット変更時
    const onChangeFormat = (val) => {
      // コンポーネント（フォーマット）変更
      const format = state.contentFormats.find((v) => v.id == val);
      state.selectComponent = state.baseComponents[format.code];
    };

    const init = () => {
      // onlySiteComponentフォルダのコンポーネントを取得する
      const files = require.context("./contentComponent", true, /\.vue$/);
      files.keys().forEach((key) => {
        set(
          state.baseComponents,
          key.replace(/(\.\/|\.vue)/g, ""),
          files(key).default
        );
      });
    };

    init();

    // 返却オブジェクト定義
    return {
      setting,
      refFormatComponent,
      ...toRefs(state),
      editDialog,
      onSubmit,
      cancelDialog,
      showDialog,
      scrollReset,
      onChangeFormat,
      select_format: [(v) => !!v || "必須選択項目です"],
    };
  },
});
</script>

<style scoped>
::v-deep #color-selector .v-input__append-inner {
  margin-top: 13px;
}
.edit_area {
  cursor: pointer;
}
::v-deep .td_content_name {
  width: 160px;
}
::v-deep .td_site_content_name {
  min-width: 160px;
}
</style>
