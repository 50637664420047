<template>
  <!-- テンプレート登録ダイアログ -->
  <div>
    <ValidationObserver ref="observer">
      <h3>ニュース種別</h3>
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="4">
            <v-select
              v-model="news_category"
              name="news_category"
              :items="newsCategories"
              no-data-text="ニュース種別を選択"
              label="ニュース種別"
              success
              outlined
              hint="ニュース種別を選択して下さい[必須項目]"
              :rules="require_rule"
              class="mt-2"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
      <!-- <h3 class="d-none">公開開始時刻設定</h3>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-radio-group v-model="publish_now" row mandatory hide-details>
              <v-radio label="すぐに公開" class="mb-2" value="1"></v-radio>
              <v-radio
                label="開始時刻を設定"
                class="mb-2"
                disabled
                value="0"
              ></v-radio>
            </v-radio-group>
            <span
              >※ヤトイテから更新する場合、「公開開始時刻」は「すぐに公開」のみ選択可能です。</span
            >
          </v-col>
        </v-row>
      </v-container> -->
      <h3>公開終了時刻設定</h3>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" sm="6">
                <v-checkbox
                  ref="indefinite"
                  v-model="indefinite_selected"
                  name="indefinite"
                  label="終了時刻を設定"
                  value="1"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12" sm="6">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      name="終了時刻"
                      :rules="
                        !indefinite_selected || indefinite_selected.length == 0
                          ? ''
                          : 'required|numeric|min_value:1|max_value:99'
                      "
                    >
                      <v-text-field
                        v-model="end_publish_between"
                        name="end_publish_between"
                        :error-messages="errors"
                        :success="valid"
                        outlined
                        background-color="white"
                        suffix="時間後"
                        :disabled="
                          !indefinite_selected ||
                          indefinite_selected.length == 0
                        "
                        class="ml-4"
                        :persistent-hint="true"
                        hint="公開終了時刻を公開から何時間後にするか設定します"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
              <!-- <v-col cols="12" md="6">
                <v-row class="mt-2">
                  <v-col cols="6">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      name="終了日付"
                      rules="required"
                    >
                      <v-text-field
                        v-model="end_date"
                        label="終了日付"
                        outlined
                        type="date"
                        :disabled="
                          !(
                            indefinite_selected.length > 0 &&
                            end_publish_type == 2
                          )
                        "
                        :error-messages="errors"
                        :success="valid"
                        hide-details
                        class="ml-1"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="6">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      name="終了時刻"
                      rules="required"
                    >
                      <v-text-field
                        v-model="end_time"
                        label="終了時刻"
                        outlined
                        type="time"
                        :disabled="
                          !(
                            indefinite_selected.length > 0 &&
                            end_publish_type == 2
                          )
                        "
                        :error-messages="errors"
                        :success="valid"
                        hide-details="true"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <div class="mt-4 text-subtitle-2">
                  ※更新時刻より前の日時が指定された時、終了時刻設定は未設定となります。
                </div>
              </v-col> -->
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <h3>題名</h3>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors, valid }"
              name="題名"
              rules="required|max:1000"
            >
              <v-text-field
                v-model="title"
                name="title"
                counter="1000"
                :error-messages="errors"
                :success="valid"
                label="題名"
                outlined
                background-color="white"
                class="mt-2"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-container>

      <h3 id="body_head">本文</h3>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors, valid }"
              name="本文"
              rules="required|max:10000"
            >
              <v-textarea
                v-model="body"
                name="body"
                counter="10000"
                :error-messages="errors"
                :success="valid"
                label="本文"
                rows="12"
                outlined
                background-color="white"
                class="mt-2"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-container>
      <!-- 画像選択コンポーネント -->
      <tabImageComponent
        ref="refTabImageComponent"
        :template_config="template_config"
        :is_contain="true"
      ></tabImageComponent>
    </ValidationObserver>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  defineComponent,
  onMounted,
} from "@vue/composition-api";
import setting from "@/common/setting.js";
import tabImageComponent from "./itemComponent/tabImage";
import utilDate from "@/common/utilDate.js";
import utilTime from "@/common/utilTime.js";

export default defineComponent({
  components: {
    tabImageComponent,
  },
  props: ["template_config"],
  setup(props, ctx) {
    const state = reactive({
      news_category: 1,
      title: "",
      body: "",
      // publish_now: 1,
      indefinite_selected: [],
      // end_publish_type: null,
      end_publish_between: 1,
      // end_date: utilDate.getDateFormatLong(new Date()),
      // end_time: utilTime.getTimeFormat(new Date()),
    });

    const newsCategories = [
      { value: 1, text: "割引情報" },
      { value: 2, text: "イベント" },
      { value: 3, text: "出勤速報" },
      { value: 4, text: "新人速報" },
      { value: 9, text: "お知らせ" },
    ];

    const endPublishBetweenItems = [
      { value: 1, text: "1時間後" },
      { value: 2, text: "2時間後" },
      { value: 3, text: "3時間後" },
      { value: 6, text: "6時間後" },
      { value: 12, text: "12時間後" },
      { value: 24, text: "1日後" },
    ];

    const clearDialog = async () => {
      state.news_category = 1;
      state.title = "";
      state.body = "";
      // state.publish_now = true;
      state.indefinite_selected = [];
      // state.end_publish_type = null;
      state.end_publish_between = 1;
      // state.end_date = utilDate.getDateFormatLong(new Date());
      // state.end_time = utilTime.getTimeFormat(new Date());
    };

    const setData = async () => {
      // 各項目に値をセット
      const config = props.template_config;
      state.news_category = config.news_category ?? 1;
      state.title = config.title_1000;
      state.body = config.body_10000;
      // state.publish_now = config.publish_now;
      state.indefinite_selected = config.indefinite_selected;
      state.end_publish_between = config.end_publish_between;
      // if (config.indefinite_selected) {
      //   state.indefinite_selected = config.indefinite_selected;
      //   state.end_publish_type = config.end_publish_type;
      //   if (config.end_publish_type == 1) {
      //     state.end_publish_between = config.end_publish_between;
      //   } else {
      //     state.end_date = config.end_date;
      //     state.end_time = config.end_time;
      //   }
      // }
    };

    onMounted(async () => {
      // 初期化
      await clearDialog();
      // データセット
      if (props.template_config) {
        await setData(props.template_config);
      }
    });

    // 保存前のチェック処理
    const checkValid = async () => {
      // 入力チェック エラー状態の部品が無いか調べる
      let isValid = await ctx.refs.observer.validate();

      let imageConfigIsValid = await ctx.refs.refTabImageComponent.checkValid();
      if (!imageConfigIsValid) {
        isValid = false;
      }

      if (!isValid) {
        isValid = false;
        // エラー処理
        const el = document.querySelectorAll(
          ".v-text-field.error--text, .v-textarea.error--text"
        );
        if (el.length > 0) {
          el[0].scrollIntoView();
        }
      }

      return isValid;
    };

    // 保存処理
    const onSubmit = async () => {
      // 保存処理
      const config = {};
      config["news_category"] = state.news_category;
      config["title_1000"] = state.title;
      config["body_10000"] = state.body;
      // config["publish_now"] = state.publish_now;
      config["indefinite_selected"] = state.indefinite_selected;
      config["end_publish_between"] = state.end_publish_between;
      // if (state.indefinite_selected.length > 0) {
      //   config["indefinite_selected"] = state.indefinite_selected;
      //   config["end_publish_type"] = state.end_publish_type;
      //   if (state.end_publish_type == 1) {
      //     config["end_publish_between"] = state.end_publish_between;
      //   } else {
      //     config["end_date"] = state.end_date;
      //     config["end_time"] = state.end_time;
      //   }
      // }

      // 画像コンポーネントの設定を取得
      const tabImageConfig = await ctx.refs.refTabImageComponent.saveConfig();

      return JSON.stringify({ ...config, ...tabImageConfig });
    };

    // 返却オブジェクト定義
    return {
      setting,
      ...toRefs(state),
      newsCategories,
      endPublishBetweenItems,
      checkValid,
      onSubmit,
      require_rule: [(v) => !!v || "必須選択項目です"],
    };
  },
});
</script>

<style scoped></style>
